import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import AdminPanel from './pages/AdminPanel/AdminPanel';
import Authorization from './pages/Authorization/Authorization';
import NewBlogers from './pages/NewBlogers/NewBlogers';
import CanceledBlogers from './pages/CanceledBlogers/CanceledBlogers';
import ProtectedRoute from './ProtectedRoute';
import AcceptedBlogers from "./pages/AcceptedBlogers/AcceptedBlogers";

const App = () => {

  const isAuthorized = () => {
    const token = localStorage.getItem('token');
    return !!token
  }

  return (
    <div className="App">
      <Switch>
        <Redirect exact from="/" to="/auth" />
        <Route path='/auth' component={Authorization} >
          {isAuthorized() ? <Redirect to="/admin/newblogers" /> : null}
        </Route>
        <ProtectedRoute exact path="/admin" component={AdminPanel} />
        <ProtectedRoute path='/admin/newblogers' component={NewBlogers} />
        <ProtectedRoute path='/admin/canceledblogers' component={CanceledBlogers} />
        <ProtectedRoute path='/admin/acceptedblogers' component={AcceptedBlogers} />
      </Switch>
    </div>
  );
  
}

export default App;
