import React from 'react';
import style from './Preloader.module.css';

const Preloader = () => {

    return (
        <div className={style.Preloader}>
        </div>
    )
}

export default Preloader