import React, { Component } from 'react';
import Menu from '../../components/Menu/Menu';
import style from './AdminPanel.module.css';

class AdminPanel extends Component {

    render() {
        return (
            <div className={style.AdminPanel}>
                <Menu/>
            </div>
        )
    }
}

export default AdminPanel 