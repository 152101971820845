import React from 'react';
import style from './BurgerMenu.module.css';

const BurgerMenu = props => {

    const  cls = [style.BurgerMenu];

    if (props.isOpen) {
        cls.push(style.open)
    } 

    return (
        <div 
            className={cls.join(' ')}
            onClick={props.onToggle}
        >
            <div/>
        </div>
    )

}
export default BurgerMenu