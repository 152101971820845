import React, { Component } from "react";
import style from "../AcceptedBlogers/AcceptedBlogers.module.css";

import CardBlogerList from '../../components/CardBloger/CardBlogerList/CardBlogerList';
import Menu from '../../components/Menu/Menu';
import Preloader from '../../components/Preloader/Preloader';
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';

class AcceptedBlogers extends Component {
  state = {
    blogers: [],
    menu: false,
    isPreloaded: true
  }

  componentDidMount() {
    const url = 'http://82.146.60.174:8888/api/v1/admin/blogger/list/?status_user=active';
    const token = localStorage.getItem('token');
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'accept': 'application/json',
      }
    };

    fetch(url, requestOptions)
      .then(async response => {
        const json = await response.json();
        if (response.ok) {
          this.setState({blogers: json, isPreloaded: false})
        } else {
          const error = json.detail
          this.setState({errorMessage: error});
          console.log('Ошибка:', error);
        }
      })
      .catch(error => {
        console.error('Ошибка:', error.message);
      });
  }


  toggleMenuHandler = () => {
    this.setState({ menu: !this.state.menu });
  };

  render() {
    const {blogers, isPreloaded} = this.state;
    return (
      <>
        <Menu isShow={this.state.menu}/>
        <div className={style.AcceptedBlogers__header}>
          <BurgerMenu
              isOpen={this.state.menu}
              onToggle={this.toggleMenuHandler}
          />
        </div>
        {
          isPreloaded
            ?   <div className={style.AcceptedBlogers}>
              <div className={style.AcceptedBlogers__spinner} >
                <Preloader/>
              </div>
            </div>
            :   <div className={style.AcceptedBlogers}>
              <div className={style.AcceptedBlogers__title}>Принятые блогеры</div>
              <div className={style.AcceptedBlogers__count}>Всего блогеров: {this.state.blogers.length}</div>
              <CardBlogerList blogers={blogers}/>
            </div>
        }

      </>
    )
  }

}

export default AcceptedBlogers