import React, { Component } from 'react';
import style from './AuthorizationForm.module.css';
import img from './../../img/logo.svg';
import login from './../../img/login-icon.svg';
import password from './../../img/password-icon.svg';

class AuthorizationForm extends Component {
    
    state = {
        login: '',
        password: '',
        errorMessage: '',
        disabled: false
    }

    handlerSubmit = event => {
        event.preventDefault();
        this.setState({disabled: true})
        const url = 'http://82.146.60.174:8888/api/v1/login';
        const data = {username: this.state.login, password: this.state.password};
        const requestOptions = {
            method: 'POST',
            headers: { 
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': '766WmtNSEofdC4HdGV45wKK3QjduNd3zUQ88d1G1LNzAouceBgvOsOvVc5haQ64I'
            },
            body: JSON.stringify(data)
        };

        fetch(url, requestOptions)
            
            .then(async response => {
                const json = await response.json();
                if (response.ok) {
                    const token =  "Token " + json.token;
                    localStorage.setItem('token', token);
                    window.location = ('/admin/newblogers');
                    this.setState({disabled: false});
                } else {
                    const error = json.error.errorData[0].message
                    this.setState({errorMessage: error, disabled: false});
                    console.log('Ошибка:', error);
                }
            })
            .catch(error => {
                console.error('Ошибка:', error.message);
            });
        this.setState({login:'', password:''})
    }

    handlerChange = event => {
        const {value, name} = event.target;
        this.setState ({ [name]: value });
    }

    render() {

        const cls = [style.AuthorizationForm__input];

        if (this.state.errorMessage) {
            cls.push(style.errorMessage)
        }

        return (
            <div className={style.AuthorizationForm}>
                <div className={style.AuthorizationForm__img}>
                    <img src={img} alt='Логотип'/>
                </div>
                <div className={style.AuthorizationForm__title}>
                    <p>Войти</p>
                </div>
                <div className={style.AuthorizationForm__subtitle}>
                    <p>Доступ к административной панели</p>
                </div>
                <form className={style.AuthorizationForm__form} onSubmit={this.handlerSubmit}>
                    <div className={cls.join(' ')} >
                        <div>
                            <img src={login} alt='Иконка логина'/>
                        </div>
                        <input 
                            name='login'
                            type='text'
                            placeholder='Логин'
                            value={this.state.login}
                            onChange={this.handlerChange}
                            required
                        />
                    </div>
                    <div className={cls.join(' ')} >
                        <div>
                            <img src={password} alt='Иконка пароля'/>
                        </div>
                        <input 
                            name='password'
                            type='password'
                            placeholder='Пароль'
                            value={this.state.password}
                            onChange={this.handlerChange}
                            required
                        />  
                    </div>
                    {this.state.errorMessage 
                        ? <div className={style.AuthorizationForm__errorMessage}>{this.state.errorMessage}</div>
                        : null
                    }
                    {this.state.disabled
                        ? <input type='submit' value='Войти' disabled/>
                        : <input type='submit' value='Войти' />
                    }
                </form>
            </div>
        )
    }        
}

export default AuthorizationForm