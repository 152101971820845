import React from 'react';
import style from './CardBlogerList.module.css';

import CardBlogerItem from '../CardBlogerItem/CardBlogerItem';

const CardBlogerList = ({blogers, getBlogersActive}) => {

    const elements = blogers.map( bloger => {
        return(
            <CardBlogerItem 
                key={bloger.id}
                getBlogersActive={getBlogersActive}
                {...bloger}
            />
        )
    })

    return (
        <div className={style.CardBlogerList}>
            {elements}
        </div>
    )
}

export default CardBlogerList