import React from 'react';
import { NavLink } from 'react-router-dom';
import style from './Menu.module.css';

const Menu = (props) => {

    const cls = [style.Menu];

    if (props.isShow) {
        cls.push(style.show)
    }

    return (
        <div className={cls.join(' ')}>
            <div className={style.Menu__profile}>
                <div>SM</div>
                <p>admin@example.com</p>
            </div>
            <ul className={style.Menu__list}>
                <li className={style.Menu__item}>
                    <NavLink 
                        to='/admin/newblogers' 
                        className={style.Menu__link} 
                        activeClassName={style.Menu__link_active} 
                    >
                        Новые блогеры
                    </NavLink>
                </li>
                <li className={style.Menu__item}>
                    <NavLink
                      to='/admin/acceptedblogers'
                      className={style.Menu__link}
                      activeClassName={style.Menu__link_active}
                    >
                        Принятые блогеры
                    </NavLink>
                </li>
                <li className={style.Menu__item}>
                    <NavLink 
                        to='/admin/canceledblogers' 
                        className={style.Menu__link} 
                        activeClassName={style.Menu__link_active} 
                    >
                        Отмененные блогеры
                    </NavLink>
                </li>
            </ul>      
        </div>
    )
}

export default Menu 