import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {

  const checkValidToken = () => {
    const token = localStorage.getItem('token');
    return !!token
  }

  return (
    <Route
        {...rest}
        render={(props) => checkValidToken()
        ? <Component {...props} />
        : <Redirect to='/auth' />}
    />
  );
}

export default ProtectedRoute