import React, {useState} from 'react';
import style from './CardBlogerItem.module.css';

import Preloader from '../../Preloader/Preloader';

const CardBlogerItem = ({id, avatar, fio, instagram, email, gender, date_of_birth, status_user, getBlogersActive}) => {

    const [preloader, setPreloader] = useState(false)

    const Rejected = () => {
        setPreloader(true)
        const url = `http://82.146.60.174:8888/api/v1/admin/blogger/${id}/`;
        const token = localStorage.getItem('token');
        const data = {
            "fio": `${fio}`,
            "instagram": `${instagram}`,
            "email": `${email}`,
            "gender": `${gender}`,
            "date_of_birth": `${date_of_birth}`,
            "status_user": 'reject'
        }
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Authorization': token,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
    
        fetch(url, requestOptions) 
        .then(async response => {
            const json = await response.json();
            if (response.ok) {
                getBlogersActive();
            } else {
                const error = json.detail
                console.log('Ошибка:', error);
            }
        })
        .catch(error => {
            console.error('Ошибка:', error.message);
        });
    }

    const Actived = () => {
        setPreloader(true)
        const url = `http://82.146.60.174:8888/api/v1/admin/blogger/${id}/`;
        const token = localStorage.getItem('token');
        const data = {
            "fio": `${fio}`,
            "instagram": `${instagram}`,
            "email": `${email}`,
            "gender": `${gender}`,
            "date_of_birth": `${date_of_birth}`,
            "status_user": 'active'
        }
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Authorization': token,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
    
        fetch(url, requestOptions) 
        .then(async response => {
            const json = await response.json();
            if (response.ok) {
                getBlogersActive();
            } else {
                const error = json.detail
                console.log('Ошибка:', error);
            }
        })
        .catch(error => {
            console.error('Ошибка:', error.message);
        });
    }

    return (
        <div id={id} className={style.CardBlogerItem}>
            {
                preloader 
                ? <Preloader/>
                : <>
                    <div className={style.CardBlogerItem__avatar}>
                        <img src={avatar} />
                    </div>
                    <div className={style.CardBlogerItem__name}>
                        <p>{fio}</p>
                    </div>
                    <div className={style.CardBlogerItem__link}>
                        <a href={'https://www.instagram.com/' + instagram.replace('@', '')}>{instagram}</a>
                    </div>
                    {
                        status_user==='check'
                        ?   <div className={style.CardBlogerItem__solution}>
                                <button onClick={Actived}>Принять</button>
                                <button onClick={Rejected}>Отклонить</button>
                            </div>
                        : null
                    }
                </>
            }

        </div>
    )
}

export default CardBlogerItem